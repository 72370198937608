@media screen and (max-width: 950px) {
  .heroText {
    width: 100% !important;
  }
  .contentWrapper {
    width: 100% !important;
  }
  .rocketWrapper{
    height: 342px;
    max-width: 100% !important;
  }
  .textAndRocketWrapper {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 489px) {
  .heroText {
    //styleName: Heading 4;
    font-size: 48px !important;
    line-height: 56px !important;
    letter-spacing: -0.9599999785423279px !important;
  }
  .paragraph {
    font-size: 20px !important;
    line-height: 32px !important;
    letter-spacing: -0.20000000298023224px !important;
  }
  .buttonWrapper{
    flex-direction: column;
    gap: 32px;
    button{
      max-width: 100%;
    }
  }
  .contentWrapper {
    padding-top: 84px !important;
    width: 100% !important;
  }
}
